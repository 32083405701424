<template>
	<div class="logaiseventextendedFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.logaiseventextended.idlog')" :value="idlogInfo"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.logaiseventextended.eventtypedescription')" :value="eventtypedescriptionInfo"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.logaiseventextended.portcall')" :value="portcallInfo"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'logaiseventextended-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'logaiseventextended'
		};
	},
	computed: {
		portcallInfo() {
			return this.model && this.model.portcall && this.model.portcall !== '' && this.model.portcall !== 'NO'
				? this.model.portcall
				: this.$t('header.logaiseventextended.novisit');
		},
		idlogInfo() {
			return this.model && this.model.idlog ? this.model.idlog : '';
		},
		eventtypedescriptionInfo() {
			return this.model && this.model.eventtypedescription ? this.model.eventtypedescription : '';
		}
	}
};
</script>
